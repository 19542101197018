var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"page__password"},[_c('y-form',{ref:"formRef",staticStyle:{"width":"100%"},attrs:{"label-width":"100px","model":_vm.model,"fields":_vm.fields,"rules":_vm.rules,"submitter":{
      submitText: '确定',
      resetButtonProps: {
        style: {
          display: 'none'
        }
      }
    }},on:{"submit":_vm.submit}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }