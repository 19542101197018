<template>
  <div class="page__password">
    <y-form
      ref="formRef"
      style="width: 100%"
      label-width="100px"
      :model="model"
      :fields="fields"
      :rules="rules"
      :submitter="{
        submitText: '确定',
        resetButtonProps: {
          style: {
            display: 'none'
          }
        }
      }"
      @submit="submit"
    ></y-form>
  </div>
</template>

<script>
export default {
  name: 'VersionRecordsAdd',
  data() {
    return {
      model: {
        oldPassword: '',
        newPassword: '',
        newPassword2: ''
      },
      fields: [
        {
          type: 'input',
          formItemProps: {
            style: 'width: 460px',
            label: '旧密码',
            prop: 'oldPassword'
          },
          attributes: {
            placeholder: '请输入旧密码'
          }
        },
        {
          type: 'password',
          formItemProps: {
            style: 'width: 460px',
            label: '新密码',
            prop: 'newPassword'
          },
          attributes: {
            placeholder: '请输入新密码'
          }
        },
        {
          type: 'password',
          formItemProps: {
            style: 'width: 460px',
            label: '确认密码',
            prop: 'newPassword2'
          },
          attributes: {
            placeholder: '请再次输入新密码'
          }
        }
      ],
      rules: {
        oldPassword: [
          { required: true, message: '请输入旧密码', trigger: 'blur' }
        ],
        newPassword: [
          { required: true, message: '请输入新密码', trigger: 'blur' },
          { min: 6, message: '密码长度是6个及以上字符', trigger: 'blur' }
        ],
        newPassword2: [
          { required: true, message: '请再次输入新密码', trigger: 'blur' },
          { min: 6, message: '密码长度是6个及以上字符', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    async submit({ oldPassword, newPassword, newPassword2 }) {
      if (newPassword !== newPassword2) {
        this.$message.error('两次密码不一致, 请重新输入')
        return
      }
      const res = await this.$serve.operatorModifyPassword({
        data: {
          oldPassword,
          newPassword
        }
      })

      if (res === this.$serve.FAIL) {
        return
      }

      this.$message.success('密码修改成功, 请重新登录')
      this.$store.commit('initialize')
    }
  }
}
</script>

<style scoped lang="scss"></style>
