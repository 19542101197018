<template>
  <div class="page__upgrade-records">
    <y-list-page
      :pro-form-style="{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start'
      }"
      :form-style="{ textAlign: 'right' }"
      inline
      label-width="100px"
      :model="model"
      :fields="fields"
      :submitter="{
        submitText: '搜索',
        resetButtonProps: {
          style: {
            display: 'none'
          }
        }
      }"
      :columns="columns"
      :fetch="fetchFn"
    >
      <template #form-before-addon>
        <el-button @click="exportData" type="primary">导出数据</el-button>
      </template>
    </y-list-page>
  </div>
</template>

<script>
export default {
  name: 'UpgradeRecords',
  data() {
    // 从链接带来查询字段
    const { searchLabel, searchValue, recordId } = this.$route.query
    return {
      recordId,
      model: {
        deviceType: '',
        searchLabel: searchLabel || 'serialNum',
        searchValue: searchValue || ''
      },
      fields: [
        {
          type: 'select',
          formItemProps: {
            label: '型号',
            prop: 'deviceType'
          },
          attributes: {
            placeholder: '请选择型号',
            options: [
              {
                label: '全部',
                value: ''
              },
              ...this.$enums.deviceTypeList
            ]
          }
        },
        {
          formItemProps: {
            label: ''
          },
          render(h, prop, field, model) {
            if (searchLabel) {
              return (
                <el-input
                  disabled
                  v-model={model['searchValue']}
                  placeholder="请输入内容"
                  class="input-with-select"
                >
                  <el-select
                    v-model={model['searchLabel']}
                    slot="prepend"
                    placeholder="请选择"
                  >
                    {searchLabel === 'softWareVersion' ? (
                      <el-option
                        label="软件版本"
                        value="softWareVersion"
                      ></el-option>
                    ) : (
                      <el-option
                        label="固件版本"
                        value="firmWareVersion"
                      ></el-option>
                    )}
                  </el-select>
                </el-input>
              )
            }
            return (
              <el-input
                v-model={model['searchValue']}
                placeholder="请输入内容"
                class="input-with-select"
              >
                <el-select
                  v-model={model['searchLabel']}
                  slot="prepend"
                  placeholder="请选择"
                >
                  <el-option label="序列号" value="serialNum"></el-option>
                  <el-option
                    label="软件版本"
                    value="softWareVersion"
                  ></el-option>
                  <el-option
                    label="固件版本"
                    value="firmWareVersion"
                  ></el-option>
                  <el-option label="邮箱" value="email"></el-option>
                </el-select>
              </el-input>
            )
          }
        }
      ],
      columns: [
        {
          label: '序列号',
          prop: 'serialNum'
        },
        {
          label: '用户名（email）',
          prop: 'email'
        },
        {
          label: '型号',
          prop: 'deviceType',
          render: (h, v) => {
            return this.$enumsMap.deviceTypeMap[v]
          }
        },
        {
          label: '升级类型',
          prop: 'wareType',
          render: (h, v) => {
            return this.$enumsMap.wareTypeMap[v]
          }
        },
        {
          label: '旧版本',
          render(h, record) {
            return (
              (record.oldVersionName || '-') +
              '(' +
              (record.oldVersionCode || '-') +
              ')'
            )
          }
        },
        {
          label: '新版本',
          render(h, record) {
            return (
              (record.newVersionName || '-') +
              '(' +
              (record.newVersionCode || '-') +
              ')'
            )
          }
        },
        {
          label: '升级时间',
          prop: 'createTime'
        }
      ]
    }
  },
  watch: {
    '$route.query'(newVal, oldVal) {
      if (
        ['softWareVersion', 'firmWareVersion'].includes(oldVal.searchLabel) &&
        !['softWareVersion', 'firmWareVersion'].includes(newVal.searchLabel)
      ) {
        window.location.reload()
      }
    }
  },
  methods: {
    getQuery({ searchLabel, searchValue, ...rest }) {
      return {
        ...rest,
        [searchLabel]: searchValue,
        recordId: this.recordId
      }
    },
    fetchFn(data) {
      return this.$serve
        .deviceUpgradeRecordList({
          params: this.getQuery(data)
        })
        .then((res) => {
          if (res === this.$serve.FAIL) {
            return Promise.reject()
          }
          return res
        })
    },
    exportData() {
      this.$download({
        fn: 'deviceUpgradeRecordExport',
        params: this.getQuery(this.model),
        filename: '升级记录.xlsx'
      })
    }
  }
}
</script>

<style lang="scss">
.page__upgrade-records {
  .input-with-select {
    .el-input-group__prepend {
      background-color: #fff;
    }
    .el-select .el-input {
      width: 120px;
    }
  }
}
</style>
